<template>
    <section class="formular">
        <v-container>
            <v-row no-gutters>
                <v-col cols="1">
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn small depressed color="gold" dark @click="dialog_sellers = true" v-on="on">
                                <v-icon small>mdi-account-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('tooltip.rent_sellers') }}</span>
                    </v-tooltip>

                    <v-dialog v-model="dialog_sellers" max-width="1050" persistent scrollable fullscreen>
                        <v-card>
                            <v-toolbar color="gold" dark dense style="flex: unset;">
                                <v-btn icon @click="dialog_sellers = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{$t('salary_reports.seller_select')}}</v-toolbar-title>
                            </v-toolbar>

                            <v-card-text class="px-0">
                                <DataSelect :data_view_name="'sellers_list_select'"/>
                            </v-card-text>
                        </v-card>

                    </v-dialog>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-1">
                <v-col class="pa-0 pb-1 grey lighten-4 rounded"
                       :class="{'darken-4' : $vuetify.theme.dark}">
                    <v-chip v-for="(seller,i) in sellers" :key="i"
                            class="px-4 ml-1 mt-1" small :class="{'user-inactive' : !seller.item.active}"
                            color="gold" dark
                            label close @click:close="clearSeller(i)"
                    >
                        <v-icon left small>mdi-account-tie</v-icon>
                        <span>{{ seller.item.fullname}}</span>
                        <span><strong>{{ '&nbsp;- ' + seller.item.rent }}</strong></span>
                    </v-chip>
                </v-col>
            </v-row>
            <v-alert v-if="sellers.length === 0"
                     dense text type="info" class="mt-2" border="left" color="gold"
            >
                {{ $t('salary_reports.no_seller_selected') }}
            </v-alert>
            <v-row v-if="sellers.length > 0" :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded pt-1">
                <v-col align="center">
                    <v-btn dense depressed color="gold" dark @click="generate">
                        <v-icon left>mdi-cog-play-outline</v-icon>
                        <span>{{ $t('salary_reports.action_buttons.generate_rent_salaries') }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {generateRentSalaries} from "@/api/salaries";
import DataSelect from "@/components/DataSelect.vue";

export default {
    name: "RentPaymentDialog",

    components: {
        DataSelect
    },

    data: () => ({
        dialog_sellers: false,
        sellers: []
    }),

    methods: {
        clearSeller(_index) {
            this.sellers.splice(_index, 1);
        },

        removeDuplicates() {
            let unique = Array.from(new Set(this.sellers.map(a => a.id)))
                .map(id => {
                    return this.sellers.find(a => a.id === id)
                });

            this.sellers = unique;
        },

        generate() {
            this.$root.$emit('overlay', true);
            generateRentSalaries({sellers: Array.from(new Set(this.sellers.map(a => a.id)))})
                .then((response) => {
                    this.$root.$emit('overlay', false);
                    if (response) {
                        this.$parent.$emit('call', {func: 'close', data: null});
                        this.$root.$emit('notification', {
                            type: 'success',
                            icon: 'update',
                            msg: 'notification.generate_rent_salaries'
                        });
                    } else {
                        this.$root.$emit('notification', {
                            type: 'error',
                            icon: 'server_error',
                            msg: 'notification.generate_rent_salaries_error'
                        });
                    }

                }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        }
    },

    mounted() {
        this.$root.$on('item-seller', (_id, _item) => {
            let tag = {};
            tag.fullname = _item.name
            tag.active = _item.active
            tag.rent = _item.total_rent

            this.sellers.push({id: _id, item: tag});
            this.removeDuplicates();

            this.dialog_sellers = false;
            this.$root.$emit('overlay', false);
        });
    },

    destroyed() {
        this.$root.$off('item-seller');
    }
}
</script>